import React from "react";
import AOS from 'aos';
import { graphql } from 'gatsby'
// import Img from "gatsby-image";
import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';


export default ({data}) => {
  // const teams = data.allWordpressWpTeams;
  const page = data.wordpressPage;
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return(
    <PrimaryLayout>
      <SEO 
        title={page.acf.seo_title}
        description={page.acf.seo_description}
        keywords={page.acf.seo_keywords}
      />
      <div className="container-fluid">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-5 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-6">
                <div className="header-text-container">
                  <div className="vertical-center" data-aos="fade-up" dangerouslySetInnerHTML={{__html: page.content}} />
                </div>
              </div>
              <div className="col-md-6">
                <img
                  data-src={page.featured_media && page.featured_media.source_url}
                  className="header-image lazyload"
                  alt="About us. Our Agency. Our Clients."
                  data-aos="fade-up"
                />
                {/* <Img 
                  fixed={page.featured_media.localFile.childImageSharp.fixed}
                  alt={page.acf.seo_title}
                  /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid primary-color-bg ">
        <div className="row p-0 m-0">
          <div className="container main-container-padding-t-2 main-container-padding-b-2">
            <div className="row">
              <div className="col-md-12">
                <div className=" header-text-container">
                  <div className="vertical-center">

                    <div data-aos="fade-up" dangerouslySetInnerHTML={{__html: page.acf.about_info}} />

                    {/* {teams.nodes.reverse().map((team, index) => (
                      <>
                        <div className="row main-container-padding-b-1 main-container-padding-t-1" key={index}>
                          <div className="col-md-3">
                            <img
                              data-src={team.featured_media.source_url}
                              width="100%"
                              className="lazyload"
                              style={{ borderRadius: "5px" }}
                              alt="team member"
                              data-aos="fade-up"
                            />
                          </div>
                          <div className="col-md-9">
                            <h2 data-aos="fade-up">{team.title}</h2>
                            <p data-aos="fade-up" className=" text-primary-color title-team">
                              {team.acf.title}
                              <br />
                              {team.acf.office}
                            </p>
                            <div data-aos="fade-up" className=" text-primary-color" dangerouslySetInnerHTML={{__html : team.content}}>
                            </div>
                          </div>
                        </div>
                        {index < (teams.nodes.length - 1) ? <hr /> : null}
                      </>
                    ))} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  )
}

export const query = graphql`
{
  wordpressPage(wordpress_id: {eq: 43}) {
    content
    acf {
      about_info      
      seo_title
      seo_keywords
      seo_description
    }
    featured_media {
      source_url
    }
  }
  allWordpressWpTeams {
    nodes {
      content
      title
      acf {
        office
        title
      }
      featured_media {
        source_url
      }
    }
  }
}
`